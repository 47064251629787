<template>
  <div>
    <div>
      <date-picker
        name="datePickerPageEl"
        :lableName="$t('Pick a date')"
        :dateOptions="{
          dateClearable: !item.Required,
          dateDisabled: $helpers.itemNeedsDisable(
            item.ReadOnly,
            needsDisable,
            editItemAdmin
          ),
          dataFieldValidation: item.FieldValidation,
          dateRules: item.FieldValidationFuntion,
          dateAutoSelectCurrent: item.AutoSelectCurrent,
          dateIsFilter: false,
        }"
        :dateValue="item.Value"
        @clearDate="item.Value = null"
        @changeDatePicker="item.Value = $event"
      />
    </div>

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col
          cols="12"
          :lg="
            item.colWidth < 7
              ? item.colWidth < 5
                ? 12
                : 4
              : item.colWidth < 10
              ? 4
              : 2
          "
        >
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              :label="$t('Column width')"
              outlined
              dense
            ></v-text-field>
            <v-switch
              flat
              :ripple="false"
              v-model="item.Required"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Field is required')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.ReadOnly"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.RemoveCard"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Remove frame')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.AutoSelectCurrent"
              class="float-left mr-3"
              :label="$t('Auto select date')"
              hide-details="auto"
            >
            </v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.FieldValidation"
              @change="$helpers.changeFieldValidation(item)"
              class="float-left mr-3"
              :label="$t('Field validation')"
              hide-details="auto"
            >
            </v-switch>
            <v-combobox
              v-if="item.FieldValidation"
              :items="$validation.getAllValidationNames()"
              class="float-left mr-3"
              :label="$t('Rule')"
              v-model="item.FieldValidationFuntion"
              clearable
              @click:clear="item.FieldValidationFuntion = ''"
              outlined
              dense
              hide-details="auto"
            >
            </v-combobox>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>